import BackendLayout from "@/layouts/backend-layout/BackendLayout.vue";
import ServerIndex from "./views/ServerIndex.vue";
import ServerServices from "./views/ServerServices.vue";
import ServerLogs from "./views/ServerLogs.vue";
import ServerDockers from "./views/ServerDockers.vue";

export default (router) => {
  router.addRoute({
    path: "/server",
    redirect: "/server/servers",
    component: BackendLayout,
    name: "Server",
    children: [
      {
        path: "/server/servers",
        name: "server.servers",
        component: ServerIndex,
        meta: {
          pageTitle: "title.server",
          breadcrumbs: ["title.server"],
          permissions: ["superAdmin"],
        },
      },
      {
        path: "/server/dockers",
        name: "server.dockers",
        component: ServerDockers,
        meta: {
          pageTitle: "title.serverDocker",
          breadcrumbs: ["title.server", "title.serverDocker"],
          permissions: ["superAdmin"],
        },
      },
      {
        path: "/server/services",
        name: "server.services",
        component: ServerServices,
        meta: {
          pageTitle: "title.serverService",
          breadcrumbs: ["title.server", "title.serverService"],
          permissions: ["superAdmin"],
        },
      },
      {
        path: "/server/logs",
        name: "server.logs",
        component: ServerLogs,
        meta: {
          pageTitle: "title.serverLog",
          breadcrumbs: ["title.server", "title.serverLog"],
          permissions: ["superAdmin"],
        },
      },
    ],
  });
  router.removeRoute("catchAll");
  router.addRoute({
    name: "catchAll",
    path: "/:pathMatch(.*)*",
    redirect: "/404",
  });
};
